import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, Spinner, Alert, Badge } from 'react-bootstrap';
import Countdown from 'react-countdown';


interface Question {
    id: number;
    title: string;
    is_multichoice: boolean | null;
    Options: Option[];
}

interface Option {
    id: number;
    title: string;
}

const QuestionDetail: React.FC = () => {
    const { groupId } = useParams<{ groupId: string }>();
    const { questionId } = useParams<{ questionId: string }>();
    const [question, setQuestion] = useState<Question | null>(null);
    const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;

    const endTimeRef = useRef<Date | null>(null);

    useEffect(() => {
        const fetchQuestion = async () => {
            try {
                const response = await fetch(`${backendDomain}/api/question/${questionId}`);
                if (response.ok) {
                    const data = await response.json();
                    const startQuestionResponse = await startQuestion();
                    if (startQuestionResponse.ok) {
                        const scannedData = await startQuestionResponse.json();

                        endTimeRef.current = new Date(new Date(scannedData.scannedQuestion.started_at).getTime() + 20000);

                        setQuestion(data);
                    } else {
                        const errorData = await startQuestionResponse.json();
                        throw new Error(errorData.error || 'Failed to load question details');
                    }
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch question details');
                }
            } catch (err: any) {
                console.error(err);
                setError(err.message);
            }
        };

        const startQuestion = async () => {
            try {
                const response = await fetch(`${backendDomain}/api/start-scanned-question/`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        questionId: questionId,
                        groupId: groupId
                    })
                })

                return response;
            } catch (err: any) {
                console.error(err);
                setError(err.message);
                throw err;
            }
        }

        fetchQuestion();
    }, [questionId]);

    const submitAnswer = async ( answers: number[], allowEmpty: boolean) => {
        if (answers.length === 0 && !allowEmpty) {
            setError('Please select an answer');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await fetch(`${backendDomain}/api/submit-answer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    questionId: question!.id,
                    answer: question!.is_multichoice ? answers : answers[0],
                    groupId: groupId,
                    allowEmpty: allowEmpty
                })
            });

            if (response.ok) {
                console.log('Answer submitted!');
                setQuestion(null);
                setSelectedAnswers([]);
                navigate(`/dashboard/${groupId}`);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to submit answer');
            }
        } catch (err: any) {
            console.error(err);
            setError(err.message);
        } finally {
            setLoading(false)
        }

    };

    const handleOptionChange = (optionId: number, isChecked: boolean) => {
        if (!question) return;

        if (question.is_multichoice) {
            if (isChecked) {
                setSelectedAnswers(prev => prev.includes(optionId) ? prev : [...prev, optionId]);
            } else {
                setSelectedAnswers(prev => prev.filter(id => id !== optionId));
            }
        } else {
            const newSelection = isChecked ? [optionId] : [];
            setSelectedAnswers(newSelection);
            if (isChecked) {
                submitAnswer(newSelection, false);
            }
        }
    }

    const handleCountdownComplete = () => {
        handleAutoSubmit();
    }

    const handleAutoSubmit = async () => {
        await submitAnswer(selectedAnswers, true);
        setError('Time is up! No answer was selected.');
        navigate(`/dashboard/${groupId}`);
        return;
    }

    if (error && !question) {
        return (
            <Container className="py-5">
                <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={6}>
                        <Alert variant="danger" className="text-center">
                            {error}
                        </Alert>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!question || endTimeRef.current === null) {
        return (
            <Container className="py-5">
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            {/* Question Title */}
                            <Card.Title className="mb-4 text-center">{question.title}</Card.Title>

                            {/* Countdown Timer */}
                            <div className="d-flex justify-content-center mb-4">
                                <Countdown
                                    date={endTimeRef.current || undefined} // 20 seconds
                                    renderer={({ hours, minutes, seconds, completed }) => {
                                        if (completed) {
                                            return null;
                                        } else {
                                            return (
                                                <Badge bg={seconds <= 10 ? "danger" : "secondary"} pill>
                                                    Time Left: {seconds} {seconds === 1 ? 'second' : 'seconds'}
                                                </Badge>
                                            );
                                        }
                                    }}
                                    onComplete={handleCountdownComplete}
                                />
                            </div>

                            {/* Error Alert */}
                            {error && (
                                <Alert variant="danger" onClose={() => setError('')} dismissible>
                                    {error}
                                </Alert>
                            )}

                            {/* Options Grid */}
                            <Form>
                                <Row>
                                    {question.Options.map((option, index) => (
                                        <Col
                                            key={option.id}
                                            xs={12}
                                            sm={6}
                                            className="mb-4"
                                        >
                                            <Card
                                                className={`h-100 ${
                                                    selectedAnswers.includes(option.id) ? 'border-primary' : ''
                                                }`}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <Card.Body className="d-flex align-items-center">
                                                            <Form.Check
                                                                type={question.is_multichoice ? "checkbox" : "radio"}
                                                                name="options"
                                                                id={`option-${option.id}`}
                                                                value={option.id}
                                                                checked={selectedAnswers.includes(option.id)}
                                                                onChange={(e) => handleOptionChange(option.id, e.target.checked)}
                                                                className="me-3"
                                                            />
                                                    <Form.Label className="flex-grow-1" style={{cursor: 'pointer'}} htmlFor={`option-${option.id}`} >
                                                        {option.title}
                                                    </Form.Label>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Form>

                            {/* Submit Button */}
                            {
                                question.is_multichoice && (
                                    <div className="d-grid">
                                        <Button variant="primary" onClick={() => submitAnswer(selectedAnswers, false)} disabled={loading || selectedAnswers.length === 0}>
                                            {loading ? (
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="me-2"
                                                    />
                                                    Submitting...
                                                </>
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </div>
                                )
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default QuestionDetail;
