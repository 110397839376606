import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { BrowserQRCodeReader } from '@zxing/browser';
import {Question} from "../interfaces/Question";

import {Container, Row, Col, Card, Button, ListGroup, Spinner, Modal, Badge, Table} from 'react-bootstrap';

const Dashboard: React.FC = () => {
    const { groupId } = useParams<{ groupId: string }>();
    const [answeredQuestions, setAnsweredQuestions] = useState<Question[]>([]);
    const [scannedQuestions, setScannedQuestions] = useState<Question[]>([]);
    const scannedQuestionsRef = useRef<Question[]>([]);  // Use a ref to track scanned questions
    const [isScanning, setIsScanning] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const codeReaderRef = useRef<BrowserQRCodeReader | null>(null);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);


    const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;

    useEffect(() => {
        const fetchAnsweredQuestions = async () => {
            const response = await fetch(`${backendDomain}/api/answered-questions/${groupId}`);
            if (response.ok) {
                const data = await response.json();
                console.log('answered', data);
                setAnsweredQuestions(data);
            } else {
                alert('Failed to fetch answered questions');
            }
        };

        fetchAnsweredQuestions();
    }, [groupId]);

    useEffect(() => {
        const fetchScannedQuestions = async () => {
            console.log('groupId', groupId)
            const response = await fetch(`${backendDomain}/api/scanned-questions/${groupId}`);
            if (response.ok) {
                const data = await response.json();
                console.log('scanned', data);
                setScannedQuestions(data);
                scannedQuestionsRef.current = data;  // Update the ref with the latest data
            } else {
                alert('Failed to fetch scanned questions');
            }
        };

        fetchScannedQuestions();
    }, [groupId]);

    useEffect(() => {
        if (isScanning && videoRef.current) {
            const codeReader = new BrowserQRCodeReader();
            codeReaderRef.current = codeReader;
            const videoElement = videoRef.current;

            const stopVideoStream = () => {
                if (videoElement && videoElement.srcObject) {
                    const stream = videoElement.srcObject as MediaStream;
                    stream.getTracks().forEach(track => track.stop());
                    videoElement.srcObject = null;
                }
            };

            const decodeCallback = async (result: any, err: any, controls: any) => {
                if (!err && result) {
                    stopVideoStream(); // Stop the video stream immediately after a successful scan
                    setIsScanning(false);
                    controls.stop();
                    await handleScan(result.getText());
                }
                if (err) {
                    // You can choose to log this or suppress it
                }
            };

            const controls = codeReader.decodeFromVideoDevice(undefined, videoElement, decodeCallback).catch(err => {
                console.error("Error initializing QR Code scanner:", err);
            });

            return () => {
                stopVideoStream();
                codeReaderRef.current = null;
            };
        }
    }, [isScanning]);

    const handleScan = useCallback(async (questionTypeHash: string) => {
        try {
            const response = await fetch(`${backendDomain}/api/get-question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: questionTypeHash,
                    groupId: groupId
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch question');
            }

            const data = await response.json();
            const question = data.question;

            // Check if the question is already scanned before storing
            if (scannedQuestionsRef.current.some(q => q.id === question.id)) {
                setModalContent('This question has already been scanned.');
                setShowModal(true);
                setLoading(false);
                setIsScanning(false);
                return;
            }

            // Store scanned question
            const storeResponse = await fetch(`${backendDomain}/api/store-scanned-question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ groupId: groupId, questionId: question.id })
            });

            if (storeResponse.ok) {
                setScannedQuestions(prev => {
                    const updatedQuestions = [...prev, question];
                    scannedQuestionsRef.current = updatedQuestions;  // Update the ref with the new question
                    return updatedQuestions;
                });
                //setModalContent('Question scanned and added successfully!');
                //setShowModal(true);
            } else {
                const errorData = await storeResponse.json();
                console.log('Failed to store scanned question: ' + errorData.error);
                setModalContent(`Failed to store scanned question: ${errorData.error}`);
                setShowModal(true);
                setIsScanning(false);
            }
        } catch (error: any) {
            console.error('Error handling scan:', error);
            setModalContent(`Error: ${error.message}`);
            setShowModal(true);
            setIsScanning(false);
        } finally {
            setLoading(false);
        }
    }, [groupId]);

    const toggleScanner = () => {
        setIsScanning(!isScanning);
    };

    const answeredQuestionIds = new Set(answeredQuestions.map(question => question.id));

    const handleCloseModal = () => setShowModal(false);

    let unAnsweredQuestions: Question[] = [];

    if (scannedQuestions.length > 0) {
        unAnsweredQuestions = scannedQuestions.filter((question)=> !answeredQuestionIds.has(question.id));
    }

    const questionTypeStats: Record<
        string,
        { scanned: number; answered: number; unanswered: number }
    > = {};

    scannedQuestions.forEach((question) => {
        const type = question.QuestionType.name;
        if (!questionTypeStats[type]) {
            questionTypeStats[type] = { scanned: 0, answered: 0, unanswered: 0 };
        }
        questionTypeStats[type].scanned++;
    });

    scannedQuestions.forEach((question) => {
        const type = question.QuestionType.name;
        const isAnswered = answeredQuestionIds.has(question.id);

        if (isAnswered) {
            questionTypeStats[type].answered++;
        } else {
            questionTypeStats[type].unanswered++;
        }
    });

    // unAnsweredQuestions.forEach((question) => {
    //    const type = question.QuestionType.name;
    //    questionTypes[type]['unanswered'] = (questionTypes[type]['unanswered'] || 0) + 1;
    // });


    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <h2 className="text-center mb-4">Dashboard</h2>

                            {/* Scanned Questions */}
                            <Card className="mb-4">
                                <Card.Header as="h5">Questions</Card.Header>
                                <Table striped bordered hover responsive className="text-center">
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Unanswered Count</th>
                                        <th>Answered Count</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                {scannedQuestions.length > 0 ? (
                                    <>
                                    {Object.entries(questionTypeStats).map(([type, stats]) => {
                                            const firstQuestion = unAnsweredQuestions.find((question) => question.QuestionType.name === type);
                                            const firstQuestionId = firstQuestion ? firstQuestion.id : null
                                            return (
                                                <tr key={type}>
                                                    <td>
                                                        {type}
                                                    </td>
                                                    <td>
                                                        {stats.unanswered > 0 ? (
                                                            <Badge bg="warning">{stats.unanswered}</Badge>
                                                        ) : (
                                                            <Badge bg="success">0</Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        { stats.answered > 0 ? (
                                                            <Badge bg="success">{stats.answered}</Badge>
                                                        ) : (
                                                            <Badge bg="warning">0</Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {stats.unanswered > 0 ? (
                                                            <Link to={`/question/${groupId}/${firstQuestionId}`} className="btn btn-primary btn-sm">
                                                                Answer
                                                            </Link>
                                                        ) : (
                                                            <span className="text-muted">Completed</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                    })}

                                        <tr>
                                            <td><strong>Total</strong></td>
                                            <td>
                                                <Badge bg="warning">
                                                    {Object.values(questionTypeStats).reduce(
                                                        (sum, stats) => sum + stats.unanswered,
                                                        0
                                                    )}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Badge bg="success">
                                                    {Object.values(questionTypeStats).reduce(
                                                        (sum, stats) => sum + stats.answered,
                                                        0
                                                    )}
                                                </Badge>
                                            </td>
                                            <td>-</td>
                                        </tr>

                                    </>
                                ) : (
                                    <tr><td colSpan={5}>No scanned questions yet.</td></tr>
                                )}
                                    </tbody>
                                </Table>
                            </Card>

                            {/* Scan QR Code Button */}
                            <div className="d-grid gap-2 mb-4">
                                <Button variant={isScanning ? "danger" : "primary"} onClick={toggleScanner}>
                                    {isScanning ? 'Stop Scanning' : 'Scan QR Code'}
                                </Button>
                            </div>

                            {/* QR Code Scanner */}
                            {isScanning && (
                                <Card className="mb-4">
                                    <Card.Header as="h5">QR Code Scanner</Card.Header>
                                    <Card.Body>
                                        <video ref={videoRef} className="w-100" style={{ maxHeight: '300px' }} />
                                    </Card.Body>
                                </Card>
                            )}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal for Notifications */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Loading Spinner */}
            {loading && (
                <div className="position-fixed top-50 start-50 translate-middle">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
        </Container>
    );
};

export default Dashboard;
